import { Routes } from '@angular/router';
import { authGuard } from '@shared/guards';
import { imgGuard } from '@shared/guards/img-guard/img.guard';
import ImagenesComponent from './shared/components/images/images.component';

export const routes: Routes = [
	{
		path: 'home',
		loadChildren: () => import('./init-app/init-app.routes')
	},
	{
		path: 'profile',
		loadChildren: () => import('./profiles/profiles.routes'),
		canActivate: [authGuard]
	},
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.routes')
	},
	{
		path: 'images',
		loadComponent: () => import('./shared/components/images/images.component'),
		canActivate: [imgGuard]
	},

	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/home'
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: '/home'
	}
];
